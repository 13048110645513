import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbyImage } from "gatsby-plugin-image";

import classNames from "classnames/bind";
import * as styles from "./BoardMembers.module.css";

export default function BoardMembers(props) {

    const cx = classNames.bind(styles);

    const classes = cx(
        "wrapper",
        props.className
    );

    return (
        <StaticQuery
            query={graphql`query BoardMembers {
                board: allMdx(
                    filter: {frontmatter: {type: {eq: "committee"}}}
                    sort: {fields: frontmatter___order, order: ASC}
                ) {
                    edges {
                        node {
                            frontmatter {
                                name
                                image {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                            }
                            body
                        }
                    }
                }
            }`}

            render={data => (
                <React.Fragment>
                    <div className={classes}>
                        <ul className={styles.list}>
                            {data.board.edges.map(person =>(
                                <React.Fragment
                                    key={person.node.frontmatter.name}
                                >
                                    {console.log(person)}
                                    <li className={styles.listItem}>
                                        {person.node.frontmatter.image &&
                                            <GatsbyImage
                                                image={person.node.frontmatter.image.childImageSharp.gatsbyImageData}
                                                alt={person.node.frontmatter.name}
                                                className={styles.thumbnail}
                                            />
                                        }
                                        <h2 className={styles.name}>{person.node.frontmatter.name}</h2>
                                        <MDXRenderer>{person.node.body}</MDXRenderer>
                                    </li>
                                </React.Fragment>
                            ))}
                        </ul>
                    </div>
                </React.Fragment>
            )}
        />
    );
}